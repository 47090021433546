<template>
  <div class="receive-audit-dialog">
    <el-dialog :title="dialogTitle" :visible="dialogVisible" width="700px" :close-on-click-modal="false" :close-on-press-escape="false" top="30px" @close="closeDialog">
      <el-form ref="form" :model="formData" :rules="rules" label-width="120px" label-position="top" size="small">
        <div class="items-wrap">
          <el-form-item label="退后金额" class="form-item1">
            <el-input :value="`￥${refundAfterAmount || 0}`" class="fill-item" disabled></el-input>
          </el-form-item>

          <el-form-item label="本次支付金额" class="form-item1" prop="payAmount">
            <el-input v-model="formData.payAmount" class="fill-item" placeholder="请输入本次支付金额" type="number"></el-input>
          </el-form-item>

          <el-form-item label="客户支付方式" class="form-item1" prop="receivePayType">
            <KeySelect :val.sync="formData.receivePayType" req-key="receivePay_type" class="fill-item" />
          </el-form-item>

          <el-form-item label="支付时间" class="form-item1" prop="payDate">
            <el-date-picker v-model="formData.payDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" class="fill-item"> </el-date-picker>
          </el-form-item>

          <el-form-item label="收款商户" class="form-item2" prop="merchantVals">
            <PayOptionsCascader default-text="" class="fill-item" :val="formData.merchantVals" @change="payOptionsCascaderChange" />
          </el-form-item>

          <el-form-item label="上传付款资料：">
            <el-upload action="#" list-type="picture-card" :auto-upload="false" :on-change="fileOnChange" :file-list="formData.files" multiple>
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img class="el-upload-list__item-thumbnail" :src="file.url" />
                <span class="el-upload-list__item-actions">
                  <span v-if="file.url" class="el-upload-list__item-delete" @click="handleRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="备注信息" class="form-item3">
            <el-input v-model="formData.registRemark" type="textarea" :rows="4" maxlength="100" show-word-limit class="fill-item" placeholder="请输入备注信息"></el-input>
          </el-form-item>
        </div>

        <h4>历史提交统计</h4>
        <el-table :data="tableData" border style="width: 100%" size="small">
          <el-table-column prop="name" label="类型"> </el-table-column>
          <el-table-column prop="refundAfterAmount" label="退后金额"> </el-table-column>
          <el-table-column prop="submitAmount" label="已提交"> </el-table-column>
          <el-table-column prop="unAuditAmount" label="待审核"> </el-table-column>
          <el-table-column prop="passedAmount" label="已通过"> </el-table-column>
          <el-table-column prop="refuseAmount" label="已驳回"> </el-table-column>
          <el-table-column prop="waitAmount" label="待提交"> </el-table-column>
          <el-table-column prop="enterAmount" label="待录入"> </el-table-column>
        </el-table>
      </el-form>

      <span class="dialog-footer">
        <el-button size="small" @click="closeDialog">取 消</el-button>
        <el-button type="primary" size="small" :loading="btnLoading" @click="submitForm"> 重新提交 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { saveReceiveAudit, uploadImage, findOrderStatistics } from '@/api/receiveAudit'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const checkPayAAmount = (rule, value, callback) => {
      if (!value || value <= 0) callback(new Error('请输入本次支付金额'))
      else if ((String(value).split('.')[1] || '').length > 2) callback(new Error('格式错误，只支持精确到小数点后两位'))
      else callback()
    }

    return {
      formData: {
        payRegistRecordId: '',
        orderId: '',
        payAmount: '',
        payDate: '',
        merchantId: '',
        voucherPics: [],
        registRemark: '',
        receivePayType: '',
        files: [],
        merchantVals: [],
        isResetSubmit: 1,
      },
      rules: {
        payAmount: { required: true, validator: checkPayAAmount, trigger: 'blur' },
        payDate: { required: true, message: '请选择支付时间', trigger: 'change' },
        receivePayType: { required: true, message: '请选择支付方式', trigger: 'change' },
        merchantVals: { required: true, message: '请选择收款商户', trigger: 'change' },
      },
      dialogTitle: '收款登记',
      tableData: [],
      btnLoading: false,
      refundAfterAmount: 0
    };
  },
  watch: {
    'formData.payAmount': {
      immediate: true,
      handler(_, oldVal) {
        console.log(this.item);
        if (oldVal) this.renderTable();
      }
    }
  },
  created() {
    if (Object.keys(this.item).length === 0) return
    const { payAmount, receivePayType, payDate, merchantId, registRemark, voucherPics, merchantInstitutionDictionaryId, merchantInstitutionId, payRegistRecordId, orderId } =
      this.item

    this.formData = {
      payAmount,
      receivePayType,
      payDate,
      merchantId,
      registRemark,
      isResetSubmit: 1,
      merchantVals: [merchantInstitutionDictionaryId, merchantInstitutionId, merchantId],
      files: (voucherPics || []).map((el) => ({ name: el, url: el })),
      voucherPics: voucherPics || [],
      payRegistRecordId,
      orderId,
    }

    this.getOrderStatistics()
  },
  methods: {
    payOptionsCascaderChange(_, path) {
      this.formData.merchantVals = path
      this.formData.merchantId = path[2]
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        try {
          this.btnLoading = true
          if (this.formData.files.length > 0) {
            const uploadRes = await Promise.all(this.formData.files.filter((el) => el.raw).map((el) => uploadImage(el.raw)))

            const imageSrcArr = uploadRes.map((el) => el.http)
            const oldImageArr = this.formData.files.filter((el) => !el.raw).map((el) => el.url)
            this.formData.voucherPics = [...oldImageArr, ...imageSrcArr]
          }

          const res = await saveReceiveAudit({ data: this.formData })
          if (!res) return
          this.$message.success('操作成功')
          this.$emit('refresh')
          this.closeDialog()
        } catch (err) {
          console.log(err)
        } finally {
          this.btnLoading = false
        }
      })
    },
    closeDialog() {
      this.$emit('update:dialogVisible', false)
    },
    handleRemove(file) {
      const index = this.formData.files.findIndex((el) => el.url === file.url)
      if (index > -1) this.formData.files.splice(index, 1)
    },
    fileOnChange(_, fileList) {
      this.formData.files = fileList
    },
    async getOrderStatistics() {
      const params = {
        data: {
          orderId: this.formData.orderId,
          ignorePayRegistRecordId: this.formData.payRegistRecordId,
        },
      }

      const res = await findOrderStatistics(params);
      this.renderTable(res);
    },
    renderTable(arr = null) {
      const arrObj = arr || this.tableData[0].source;
      const {refundAfterAmount, submitAmount, unAuditAmount, passedAmount, refuseAmount} = arrObj;
      const payAmount = Number(this.formData.payAmount || 0);
      this.refundAfterAmount = refundAfterAmount;
      const enterAmount =
        parseFloat(refundAfterAmount) -
        (parseFloat(unAuditAmount) +
          parseFloat(passedAmount) +
          parseFloat(refuseAmount) +
          parseFloat(payAmount));
  
      this.tableData = [
        {
          name: '金额合计',
          refundAfterAmount,
          submitAmount,
          unAuditAmount,
          passedAmount,
          refuseAmount,
          waitAmount: Number(payAmount.toFixed(2)) || 0,
          enterAmount: Number(enterAmount.toFixed(2)) || 0,
          source: arrObj
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.receive-audit-dialog {
  .items-wrap {
    white-space: normal;

    .fill-item {
      width: 100%;
    }

    .form-item1,
    .form-item2 {
      width: 32%;
      margin-right: 1%;
      display: inline-block;
    }

    .form-item2 {
      width: 47%;
    }

    .form-item3 {
      width: 98%;
    }
  }

  .dialog-footer {
    text-align: right;
    display: inline-block;
    width: 100%;
    margin-top: 15px;
  }

  ::v-deep(.el-upload-list__item) {
    > div {
      height: 100%;
    }
  }
}
</style>
