<template>
  <div class="receive-audit-list">
    <el-tabs v-model="tabValue" @tab-click="tabClick">
      <el-tab-pane label="收款登记审核" name="1"></el-tab-pane>
      <el-tab-pane label="退款登记审核" name="2"></el-tab-pane>
    </el-tabs>
    <div class="search-bar">
      <span class="label">订单号：</span>
      <el-input v-model="search.orderNo" placeholder="输入订单号" clearable size="small" class="input"> </el-input>

      <span class="label">手机号码：</span>
      <el-input v-model="search.phoneNumber" placeholder="输入手机号码" clearable size="small" class="input"> </el-input>

      <span class="label">审核状态：</span>
      <el-select v-model="search.auditStatus" placeholder="请选择" size="small" class="select">
        <el-option v-for="item in statusOptions" :key="item.label" :label="item.label" :value="item.value"> </el-option>
      </el-select>

      <span class="label">审核人：</span>
      <el-input v-model="search.auditUserName" placeholder="输入审核人" clearable size="small" class="input"> </el-input>

      <span class="label">{{ tabValue === '1' ? '收' : '打' }}款时间：</span>
      <el-date-picker
        v-model="updateTimeArr"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        class="date-picker"
      >
      </el-date-picker>

      <span class="label">审核时间：</span>
      <el-date-picker
        v-model="auditTimeArr"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        class="date-picker"
      >
      </el-date-picker>

      <span class="label">所属大区：</span>
      <KeySelect :val.sync="search.cityId" class="select" city />

      <el-button type="primary" size="small" class="btn" icon="el-icon-search" @click="searchList"> 查询 </el-button>

      <el-button size="small" class="btn" icon="el-icon-refresh-left" @click="resetSearch"> 重置 </el-button>
    </div>

    <div v-if="totalStatisticsArr.length > 0" class="total-list">
      <div v-for="item in totalStatisticsArr" :key="item.auditStateName" class="total-item" @click="filterStatusList(item.auditState)">
        <div>
          {{ item.auditStateName || '' }} <span>({{ item.registRecordNum || 0 }})</span>
        </div>

        <div>¥{{ item.amount || 0 }}</div>
      </div>
    </div>

    <el-table
      v-if="showTabel"
      ref="table"
      :data="tableData"
      style="width: 100%"
      class="table"
      border
      :header-cell-style="{ 'background-color': '#f5f7fa' }"
    >
      <el-table-column prop="payRegistRecordCode" :label="`${tabValue === '1'?'收':'退'}款编号`" width="180"> </el-table-column>

      <el-table-column :label="`关联${tabValue === '1'?'收':'退'}款单`" width="80">
        <template slot-scope="scope">
          <el-button type="text" @click="openPageService(1, scope.row)">
            {{ scope.row.orderRegistNum }}
          </el-button>
        </template>
      </el-table-column>

      <el-table-column prop="cityName" label="所属大区" width="80"> </el-table-column>

      <el-table-column label="订单号" width="230">
        <template slot-scope="scope">
          <el-button type="text" @click="openPageService(2, scope.row)">
            {{ scope.row.orderNo }}
          </el-button>
        </template>
      </el-table-column>

      <el-table-column v-if="tabValue === '1'" key label="支付单号" width="230">
        <template slot-scope="scope">
          <el-button type="text" @click="openPageService(3, scope.row)">
            {{ scope.row.paymentOrderNo }}
          </el-button>
        </template>
      </el-table-column>

      <el-table-column prop="receivableAmount" label="订单金额"> </el-table-column>
      <template v-if="tabValue === '1'">
        <el-table-column prop="payAmount" label="支付金额"> </el-table-column>
        <el-table-column prop="payDate" label="支付时间"> </el-table-column>
        <el-table-column prop="receivePayTypeName" label="支付方式" width="120"> </el-table-column>
      </template>
      <template v-if="tabValue === '2'">
        <el-table-column prop="payAmount" label="退款金额"> </el-table-column>
        <el-table-column prop="payDate" label="打款时间"> </el-table-column>
        <el-table-column prop="receivePayTypeName" label="退款方式" width="120"> </el-table-column>
      </template>

      <el-table-column label="客户姓名" width="120">
        <template slot-scope="scope">
          <el-button type="text" @click="openPageService(4, scope.row)">
            {{ scope.row.customerName }}
          </el-button>
        </template>
      </el-table-column>

      <el-table-column prop="phoneNumber" label="客户电话" width="120"> </el-table-column>
      <el-table-column prop="saleUserName" label="关联销售" width="120"> </el-table-column>
      <el-table-column prop="saleUserDepartmentNames" label="销售部门"> </el-table-column>
      <el-table-column prop="createTime" label="提交时间"> </el-table-column>
      <el-table-column prop="auditUserName" label="最后审核人" width="120"> </el-table-column>
      <el-table-column prop="auditUserDepartmentNames" label="审核人部门"> </el-table-column>
      <el-table-column prop="auditTime" label="最后审核时间"> </el-table-column>
      <el-table-column label="审核状态" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.auditStatus == 0 ? 'info' : scope.row.auditStatus == 1 ? 'success' : scope.row.auditStatus == 2 ? 'danger' : 'warning'">
            {{ scope.row.auditStatus == 0 ? '待审核' : scope.row.auditStatus == 1 ? '已通过' : scope.row.auditStatus == 2 ? '已驳回' : '已关闭' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <template v-if="tabValue === '1'">
            <el-button
              v-if="scope.row.auditStatus == 0"
              type="text"
              size="small"
              @click="processItem(scope.row, 1)"
            >
              收款审核
            </el-button>
            <el-button
              v-if="scope.row.auditStatus != 0"
              type="text"
              size="small"
              @click="processItem(scope.row, 2)"
            >
              审核记录
            </el-button>

            <el-button
              v-if="scope.row.auditStatus == 2"
              type="text"
              size="small"
              @click="processItem(scope.row, 3)"
            >
              重新提交
            </el-button>
          </template>

          <template v-else>
            <el-button
              v-if="scope.row.auditStatus == 0"
              type="text"
              size="small"
              @click="refundReview(scope.row, 1)"
            >
              退款审核
            </el-button>
            <el-button
              v-if="scope.row.auditStatus != 0"
              type="text"
              size="small"
              @click="refundReview(scope.row, 2)"
            >
              审核记录
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer">
      <el-pagination background layout="total,prev,pager,next,sizes" :total="total" :page-sizes="[10, 50, 100, 200]" @size-change="pageSizeChange" @current-change="pageChange">
      </el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="700px" :close-on-click-modal="false" :close-on-press-escape="false" top="30px">
      <AuditDialog v-if="dialogVisible" :item="currentItem" :type="dialgType" @refresh="getList" @close="dialogVisible = false" />
    </el-dialog>
    <el-dialog
      :title="dialogRefundTitle"
      :visible.sync="dialogRefundVisible"
      width="700px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="30px"
    >
      <RefundAuditDialog
        v-if="dialogRefundVisible"
        :item="currentItem"
        :type="dialgType"
        @refresh="getList"
        @close="dialogRefundVisible = false"
      />
    </el-dialog>

    <ResetDialog v-if="resetDialogVisible" :item="currentItem" :dialog-visible.sync="resetDialogVisible" @refresh="getList" />
  </div>
</template>

<script>
import { getReceiveAuditList, findTotalOrderStatistics } from '@/api/receiveAudit';
import AuditDialog from './components/AuditDialog.vue';
import RefundAuditDialog from './components/RefundAuditDialog.vue';
import ResetDialog from './components/ResetDialog.vue';

export default {
  components: {
    AuditDialog,
    ResetDialog,
    RefundAuditDialog
  },
  data() {
    return {
      tableData: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      search: {
        auditStatus: '',
        auditUserName: '',
        phoneNumber: '',
        orderNo: '',
        cityId: '',
        payDateStart: '',
        payDateEnd: '',
        auditTimeStart: '',
        auditTimeEnd: '',
        merchantId: '',
        registerType: '1'
      },
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 0,
          label: '待审核',
        },
        {
          value: 1,
          label: '已通过',
        },
        {
          value: 2,
          label: '已驳回',
        },
        {
          value: 3,
          label: '已关闭',
        },
      ],
      dialogVisible: false,
      dialogTitle: '收款审核',
      updateTimeArr: [],
      auditTimeArr: [],
      currentItem: {},
      dialgType: 'audit',
      totalStatisticsArr: [],
      resetDialogVisible: false,
      tabValue: '1',
      showTabel: true,
      dialogRefundTitle: '退款审核',
      dialogRefundVisible: false
    };
  },
  computed: {
    searchCondition() {
      const obj = { ...this.search }

      const [updateTimeStart, updateTimeEnd] = this.updateTimeArr || [];
      obj.payDateStart = updateTimeStart || '';
      obj.payDateEnd = updateTimeEnd || '';

      const [auditTimeStart, auditTimeEnd] = this.auditTimeArr || []
      obj.auditTimeStart = auditTimeStart || ''
      obj.auditTimeEnd = auditTimeEnd || ''

      return {
        data: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }
    },
  },
  mounted() {
    this.search.merchantId = this.$route.query.merchantId || '';
    this.search.orderNo = this.$route.query.orderNo || '';
    this.tabValue = this.$route.query.type || '1';
    this.getList();
    this.getOrderStatistics();
  },
  methods: {
    tabClick({ name }) {
      this.resetSearch();
      this.doLayout();
    },
    // 重新布局table
    doLayout() {
      this.showTabel = false;
      this.$nextTick(() => (this.showTabel = true));
    },
    // 退款审核
    refundReview(row, type) {
      this.currentItem = { ...row };

      if (type === 1 || type === 2) {
        this.dialogRefundVisible = true;
        this.dialgType = type === 1 ? 'audit' : 'history';
      }

      if (type === 3) this.resetDialogVisible = true;
    },
    processItem(row, type) {
      this.currentItem = { ...row }
      type = Number(type)
      if (type === 1 || type === 2) {
        this.dialogVisible = true
        this.dialgType = type === 1 ? 'audit' : 'history'
      }

      if (type === 3) this.resetDialogVisible = true
    },
    resetSearch() {
      Object.keys(this.search).forEach((el) => {
        this.search[el] = '';
      });
      this.search.registerType = this.tabValue;
      this.updateTimeArr = [];
      this.auditTimeArr = [];
      this.pageNumber = 1;
      this.getList();
      this.getOrderStatistics();
    },
    getList() {
      this.tableData = [];
      getReceiveAuditList(this.searchCondition)
        .then((res) => {
          const { content, total } = res || {}
          this.tableData = content || []
          this.total = Number(total || '0')
          this.merchantId = ''
        })
        .catch((err) => console.log(err))
    },
    pageSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    pageChange(val) {
      this.pageNumber = val
      this.getList()
    },
    searchList() {
      this.pageNumber = 1
      this.getList()
      this.getOrderStatistics()
    },
    filterStatusList(status) {
      if (status < 0 || status > 4) return
      this.search.auditStatus = status
      this.pageNumber = 1
      this.getList()
    },
    async getOrderStatistics() {
      const res = await findTotalOrderStatistics({ data: this.search })
      this.totalStatisticsArr = res || []
    },
    openPageService(type = 0, row = {}) {
      type = Number(type)
      if (type === 1) {
        this.search.orderNo = row.orderNo
        this.pageNumber = 1
        this.getList()
        return
      }

      const origin = window.location.origin

      if (type === 2 && row.orderId && row.orderId.toString() !== '0') {
        window.open(`${origin}/index/service/receivingOrder/receivingParticulars?id=${row.orderId}`)

        return
      }

      if (type === 3 && row.paymentOrderNo && row.paymentOrderNo.toString() !== '0') {
        window.open(`${origin}/index/pay/record?paymentOrderNo=${row.paymentOrderNo}`)
        return
      }

      if (type === 4 && row.customerId && row.customerId.toString() !== '0') {
        window.open(`${origin}/index/chance/clientFollowUp/particulars?id=${row.customerId}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.receive-audit-list {
  background-color: white;
  padding: 20px;
  color: #363f47;

  .search-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > :nth-child(n) {
      margin-top: 20px;
    }

    .label {
      font-size: 14px;
      margin-left: 15px;
    }

    .select {
      width: 130px;
    }

    .input {
      width: 220px;
    }

    .date-picker {
      width: 380px;
    }

    .btn {
      margin-left: 15px;
    }
  }

  .table {
    margin-top: 20px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }

  .dialog-footer {
    text-align: right;
  }

  .total-list {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;

    .total-item {
      text-align: left;
      cursor: pointer;
      padding: 10px;

      & div:nth-child(1) {
        font-size: 14px;
        font-weight: Bold;

        span {
          color: rgba($color: #363f47, $alpha: 0.6);
          font-weight: normal;
        }
      }

      & div:nth-child(2) {
        margin: 6px 0;
        font-size: 20px;
      }

      &:hover {
        background-color: rgba(102, 102, 102, 0.08);
        border-radius: 4px;
      }
    }
  }
  :deep .el-tabs__header {
    margin: 0;
  }
}
</style>
