import request from '@/utils/request';

export function getDictionary(data) {
  return request({
    url: 'misc-web-api/admin/dictionary/getDictionary',
    method: 'POST',
    data
  });
}

export function getCityList(data) {
  return request({
    url: 'misc-web-api/api/photo/city/findNotDisableList',
    method: 'POST',
    data
  });
}

export function getUserList(data) {
  return request({
    url: 'user-web-api/admin/user/info/platform/pageList',
    method: 'POST',
    data
  });
}

export function getMerchantInstitutionManageList(data) {
  return request({
    url: 'pay-web-api/admin/merchantInstitutionManage/pageList',
    method: 'POST',
    data
  });
}

export function saveMerchantInstitutionManage(data) {
  return request({
    url: 'pay-web-api/admin/merchantInstitutionManage/save',
    method: 'POST',
    data
  });
}

export function stopEnableMerchantInstitutionManage(data) {
  return request({
    url: 'pay-web-api/admin/merchantInstitutionManage/stopEnable',
    method: 'POST',
    data
  });
}

export function deleteMerchantInstitutionManage(data) {
  return request({
    url: 'pay-web-api/admin/merchantInstitutionManage/deleteByIds',
    method: 'POST',
    data
  });
}

export function getMerchantManageList(data) {
  return request({
    url: 'pay-web-api/admin/merchantManage/pageList',
    method: 'POST',
    data
  });
}

export function stopEnableMerchantManage(data) {
  return request({
    url: 'pay-web-api/admin/merchantManage/stopEnable',
    method: 'POST',
    data
  });
}

export function deleteMerchantManage(data) {
  return request({
    url: 'pay-web-api/admin/merchantManage/deleteByIds',
    method: 'POST',
    data
  });
}

export function getMerchantManageAllList(data) {
  return request({
    url: 'pay-web-api/admin/merchantManage/list',
    method: 'POST',
    data
  });
}

export function getMerchantInstitutionManageAllList(data) {
  return request({
    url: 'pay-web-api/admin/merchantInstitutionManage/list',
    method: 'POST',
    data
  });
}

export function saveMerchantManage(data) {
  return request({
    url: 'pay-web-api/admin/merchantManage/save',
    method: 'POST',
    data
  });
}

export function getReceiveAuditList(data) {
  return request({
    url: 'pay-web-api/admin/pay/regist/record/findPageList',
    method: 'POST',
    data
  });
}

export function changeReceiveAuditStatus(data) {
  return request({
    url: 'pay-web-api/admin/pay/regist/record/updateAudit',
    method: 'POST',
    data
  });
}

export function findTotalOrderStatistics(data) {
  return request({
    url: 'pay-web-api/admin/pay/regist/record/findTotalOrderStatistics',
    method: 'POST',
    data
  });
}

export function findOrderStatistics(data) {
  return request({
    url: 'pay-web-api/admin/pay/regist/record/findOrderStatistics',
    method: 'POST',
    data
  });
}

export function saveReceiveAudit(data) {
  return request({
    url: 'pay-web-api/admin/pay/regist/record/save',
    method: 'POST',
    data
  });
}

export function uploadImage(file) {
  const data = new FormData();
  data.append('file', file);

  return request({
    url: 'misc-web-api/common/file/uploadImage',
    method: 'POST',
    data,
    header: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function paymentorderFindTotalStatistics(data) {
  return request({
    url: 'pay-web-api/admin/paymentorder/findTotalStatistics',
    method: 'POST',
    data
  });
}
