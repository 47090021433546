<template>
  <div class="audit-dialog">
    <el-form ref="form" :model="formData" :rules="rules" label-width="120px" label-position="top" size="small">
      <h3>订单信息</h3>
      <div class="items-wrap">
        <el-form-item label="订单编号" class="form-item1">
          <span>{{ item.orderNo }}</span>
        </el-form-item>

        <el-form-item label="订单金额" class="form-item1">
          <span>￥{{ item.receivableAmount }}</span>
        </el-form-item>

        <el-form-item label="产品名称" class="form-item1">
          <span>{{ item.productNames }}</span>
        </el-form-item>

        <el-form-item label="客户编号" class="form-item1">
          <span>{{ item.customerId }}</span>
        </el-form-item>

        <el-form-item label="客户姓名" class="form-item1">
          <span>{{ item.customerName }}</span>
        </el-form-item>

        <el-form-item label="客户电话" class="form-item1">
          <span>{{ item.phoneNumber }}</span>
        </el-form-item>

        <el-form-item label="销售姓名" class="form-item1">
          <span>{{ item.saleUserName }}</span>
        </el-form-item>

        <el-form-item label="销售部门" class="form-item1">
          <span>{{ item.saleUserDepartmentNames }}</span>
        </el-form-item>
      </div>

      <h3>支付信息</h3>
      <div class="items-wrap">
        <el-form-item label="本次支付金额" class="form-item1">
          <el-input v-model="item.payAmount" class="fill-item" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="客户支付方式" class="form-item1" prop="receivePayType">
          <KeySelect :val.sync="formData.receivePayType" req-key="receivePay_type" class="fill-item" :disabled="isHistory" />
        </el-form-item>

        <el-form-item label="支付时间" class="form-item1" :class="showPayTimeTips && 'paytime'" prop="payDate">
          <el-date-picker v-model="formData.payDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" class="fill-item" :disabled="isHistory"> </el-date-picker>
        </el-form-item>

        <el-form-item label="收款商户" class="form-item2" prop="merchantVals">
          <PayOptionsCascader :default-text="item.merchantName" class="fill-item" :val="formData.merchantVals" :disabled="isHistory" @change="payOptionsCascaderChange" />
        </el-form-item>

        <el-form-item label="支付凭证">
          <el-image v-for="img in item.voucherPics || []" :key="img" style="width: 100px; height: 100px; margin-right: 10px" :src="img" :preview-src-list="[img]"></el-image>
        </el-form-item>

        <el-form-item label="备注信息" class="form-item2">
          <el-input
            v-model="item.registRemark"
            type="textarea"
            :rows="6"
            maxlength="100"
            show-word-limit
            class="fill-item"
            placeholder="请填写备注信息"
            :disabled="true"
          ></el-input>
        </el-form-item>

        <el-form-item label="审核意见" class="form-item2">
          <el-input
            v-model="formData.auditOpinion"
            type="textarea"
            :rows="6"
            maxlength="100"
            show-word-limit
            class="fill-item"
            placeholder="请填写审核意见"
            :disabled="isHistory"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>

    <el-alert v-if="item.auditStatus == 3" :title="item.remark || '已关闭 原因：订单取消'" type="warning" class="close-tips" :closable="false"> </el-alert>

    <span class="footer">
      <el-button size="small" @click="close">取 消</el-button>

      <template v-if="!isHistory">
        <el-button type="danger" size="small" @click="submit(2)">驳 回</el-button>
        <el-button type="primary" size="small" @click="submit(1)">通 过</el-button>
      </template>
    </span>

    <img v-if="item.auditStatus == 1" src="@/assets/images/audit_success.png" class="status-img" />
    <img v-if="item.auditStatus == 2" src="@/assets/images/audit_error.png" class="status-img" />
  </div>
</template>

<script>
import { changeReceiveAuditStatus } from '@/api/receiveAudit'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'audit',
    },
  },
  data() {
    return {
      formData: {
        receivePayType: '',
        payDate: '',
        auditOpinion: '',
        merchantId: '',
        auditStatus: '',
        merchantVals: [],
        payRegistRecordId: '',
      },
      rules: {
        merchantVals: { required: true, message: '请选择收款商户', trigger: 'change' },
        payDate: { required: true, message: '请选择支付时间', trigger: 'change' },
        receivePayType: { required: true, message: '请选择支付方式', trigger: 'change' },
      },
      showPayTimeTips: false,
    }
  },
  computed: {
    isHistory() {
      return this.type === 'history'
    },
  },
  created() {
    if (Object.keys(this.item).length > 0) {
      const { receivePayType, payDate, auditOpinion, merchantId, merchantInstitutionDictionaryId, merchantInstitutionId, payRegistRecordId } = this.item

      this.formData = {
        receivePayType,
        payDate,
        auditOpinion,
        merchantId,
        merchantVals: [merchantInstitutionDictionaryId, merchantInstitutionId, merchantId],
        payRegistRecordId,
      }
      if(Number (this.item.payAmount) === 0) this.formData.receivePayType = ''
      this.changePayTime(payDate)
    }
  },
  methods: {
    changePayTime(e) {
      console.log(e)
      if (this.type !== 'audit') return false
      const today = new Date()
      const year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const day = String(today.getDate()).padStart(2, '0')

      const formattedDate = `${year}-${month}-${day}`
      console.log(formattedDate)
      this.showPayTimeTips = e !== formattedDate
    },
    submit(auditStatus) {
      this.$refs.form.validate((valid) => {
        if (!valid) return

        changeReceiveAuditStatus({ data: { ...this.formData, auditStatus } })
          .then((res) => {
            if (!res) return
            this.$message.success('操作成功')
            this.$emit('refresh')
            this.close()
          })
          .catch((err) => console.log(err))
      })
    },
    close() {
      this.$emit('close')
    },
    payOptionsCascaderChange(_, path) {
      this.formData.merchantVals = path
      this.formData.merchantId = path[2]
    },
  },
}
</script>

<style lang="scss" scoped>
.audit-dialog {
  position: relative;

  .items-wrap {
    white-space: normal;

    .fill-item {
      width: 100%;
    }

    .form-item1,
    .form-item2 {
      width: 32%;
      margin-right: 1%;
      display: inline-block;
      vertical-align: text-top;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      display: -webkit-inline-box;
    }

    .form-item2 {
      width: 47%;
    }
  }

  .close-tips {
    margin-bottom: 15px;
  }

  .footer {
    text-align: right;
    display: inline-block;
    width: 100%;
  }

  .status-img {
    position: absolute;
    right: 10px;
    bottom: 100px;
    width: 120px;
    height: 120px;
  }
}
.paytime {
  ::v-deep .el-form-item__label::after {
    display: inline-block;
    content: '(确保支付时间的准确)';
    color: #ff0000;
    font-size: 14px;
    margin-left: 2px;
  }
}
</style>
